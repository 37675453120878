import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Banner from './Banner';
import TermsComponent from './TermsComponent';
function Terms() {
  return (
    <div>
        <Header/>
        <Banner/>
        <TermsComponent/>
        <Footer/>
    </div>
   
  );
}

export default Terms;