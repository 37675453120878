import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
function Banner() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="bannerhome" >
    <Slider {...settings}>
<div className='homeslider'>
  <div className='row'>
    <div className='col-md-6'>
<img src="images/1.jpeg" style={{width:"100%"}}/>
    </div>
    <div className='col-md-6 detail'>
      <h2>Welcome to the Nidhi Construction</h2>
<h3>We build quality <br/>industrial</h3>
<p>As a progressive business, committed to doing the right thing, Galliford Try recognises the urgency of the climate change agenda and champions the role we have to play in decarbonising the economy for a greener, more sustainable future. </p>
<Link to= "/about" class="btn btn--green">About</Link>
    </div>
  </div>
</div>
<div className=' container homeslider'>
  <div className='row'>
    <div className='col-md-6'>
<img src="images/5.jpeg" style={{width:"100%"}}/>
    </div>
    <div className='col-md-6 detail'>
      <h4>Height quality Construction Solution for Residentials & industrial</h4>
<h3>Provide Effective building Solutions</h3>
<p>As a progressive business, committed to doing the right thing, Galliford Try recognises the urgency of the climate change agenda and champions the role we have to play in decarbonising the economy for a greener, more sustainable future. </p>
<Link to= "/about" class="btn btn--green">About</Link>
    </div>
  </div>
</div>
<div className=' container homeslider'>
  <div className='row'>
    <div className='col-md-6'>
<img src="images/3.jpeg" style={{width:"100%"}}/>
    </div>
        <div className='col-md-6 detail'>
      <h4>Height quality Construction Solution for Residentials & industrial</h4>
<h3>Provide Effective building Solutions</h3>
<p>As a progressive business, committed to doing the right thing, Galliford Try recognises the urgency of the climate change agenda and champions the role we have to play in decarbonising the economy for a greener, more sustainable future. </p>
<Link to= "/about" class="btn btn--green">About</Link>
    </div>
  </div>
</div>
<div className=' container homeslider'>
  <div className='row'>
    <div className='col-md-6'>
<img src="images/4.jpeg" style={{width:"100%"}}/>
    </div>
        <div className='col-md-6 detail'>
      <h4>Height quality Construction Solution for Residentials & industrial</h4>
<h3>Provide Effective building Solutions</h3>
<p>As a progressive business, committed to doing the right thing, Galliford Try recognises the urgency of the climate change agenda and champions the role we have to play in decarbonising the economy for a greener, more sustainable future. </p>
<Link to= "/about" class="btn btn--green">About</Link>
    </div>
  </div>
</div>
<div className=' container homeslider'>
  <div className='row'>
    <div className='col-md-6'>
<img src="images/5.jpeg" style={{width:"100%"}}/>
    </div>
        <div className='col-md-6 detail'>
      <h4>Height quality Construction Solution for Residentials & industrial</h4>
<h3>Provide Effective building Solutions</h3>
<p>As a progressive business, committed to doing the right thing, Galliford Try recognises the urgency of the climate change agenda and champions the role we have to play in decarbonising the economy for a greener, more sustainable future. </p>
<Link to= "/about" class="btn btn--green">About</Link>
    </div>
  </div>
</div>
<div className=' container homeslider'>
  <div className='row'>
    <div className='col-md-6'>
<img src="images/3.jpg" style={{width:"100%"}}/>
    </div>
        <div className='col-md-6 detail'>
      <h4>Height quality Construction Solution for Residentials & industrial</h4>
<h3>Provide Effective building Solutions</h3>
<p>As a progressive business, committed to doing the right thing, Galliford Try recognises the urgency of the climate change agenda and champions the role we have to play in decarbonising the economy for a greener, more sustainable future. </p>
<Link to= "/about" class="btn btn--green">About</Link>
    </div>
  </div>
</div>
</Slider>
</div>
   
  );
}

export default Banner;