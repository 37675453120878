import React from 'react';
import { Card } from 'antd';
function Whowe() {
  return (
    <div>
<div class="who-we-are">
<h3 class="heading-tertiary u-margin-bottom-small">NIDHI CONSTRUCTION COMPANY</h3>
<div class="who-we-are__content">
  <div class="who-we-are__left">
    <h2 class="heading-secondary u-margin-bottom-small">Who we are</h2>
    <p class="paragraph">
      We are a well established construction company with over 20 years
      experience. Specialising in the public and private sector, We
      strive to form close working relationships with clients and
      industry professionals to provide a full service that reflects
      attention to detail and quality, put simply we build solutions
      together.
    </p>
  </div>

  <div class="who-we-are__right " >
  
    <p class="who-we-are__paragraph paragraph card">
    <Card >
      <span class="emphasis">20</span>
      Years of Experience
      </Card>
    </p>

    <p class="who-we-are__paragraph paragraph card">
    <Card>
      <span class="emphasis">40+</span>
      Clients all over the world
      </Card>
    </p>

    <p class="who-we-are__paragraph paragraph card">
    <Card >
      <span class="emphasis">20.2 p</span>
      Interim dividend per share up 83.3%
      </Card>
    </p>

    <p class="who-we-are__paragraph paragraph card">
    <Card >
      <span class="emphasis">GHc1m</span>
      Construction Backlog Revenue
      </Card>
    </p>
  </div>
</div>
</div>
</div>
  );
}

export default Whowe