import React from 'react';
function Mission() {
  return (
    <div>
<div class="mission">
<h3 class="heading-tertiary u-margin-bottom-small">Our Mission</h3>
<div class="mission__container">
  <div class="mission__content-box mission__left">
    <div class="mission__content">
      <h4 class="mission__title u-margin-bottom-small">Vision</h4>
      <p class="paragraph">
        Our Vision is to be one of the prestigious Building Construction
        organizations providing lasting edifice for our clients all over
        Africa, with world-class technology at a competitive cost.
      </p>
    </div>

    <div class="mission__content">
      <h4 class="mission__title u-margin-bottom-small">Mission</h4>
      <p class="paragraph">
        Our mission as an organization is to bridge the gap between
        Africa and the western world in terms of quality product,
        service delivery.
      </p>
    </div>
  </div>

  <div class="mission__right">
    <div class="mission__img-box">
      <img
        src="images/mission-img-2x.png"
        alt="Mission Image"
        class="mission__img"
        srcset="images/mission-img-1x.png 1x, images/mission-img-2x.png 2x"
      />
    </div>
  </div>
</div>
</div>
</div>
  );
}

export default Mission