import React from 'react';
function Weoffer() {
  return (
    <div>
<div class="what-we-offer">
        <div class="heading__fix u-center-text">
          <h3 class="heading-tertiary u-margin-bottom-small">Our services</h3>
        </div>

        {/* <h2 class="heading-secondary u-margin-bottom-small u-center-text">
          What we do offer
        </h2> */}
        <p class="paragraph u-margin-bottom-big u-center-text">
          As a leader in our industry, we have always worked to develop advanced
          technology and innovative methods.
        </p>

        <div class="what-we-offer__content-box">
          <div class="what-we-offer__content">
            <h4 class="what-we-offer__title u-margin-bottom-small">
              <span class="icon-box icon">
                  <img src="images/icon/crane.png"/>
             </span>
              New Building Construction
            </h4>
            <p class="paragraph">
              Whatever your construction requirements we can help you - from new
              builds to refurbishments.
            </p>
          </div>
          <div class="what-we-offer__content">
            <h4 class="what-we-offer__title u-margin-bottom-small">
              <span class="icon-box icon">
              <img src="images/icon/divider.png"/>
              </span>
              Architectural Designs
            </h4>
            <p class="paragraph">
              Building Design, Interior Design, Structural Design, 3D
              Visualization.
            </p>
          </div>
          <div class="what-we-offer__content">
            <h4 class="what-we-offer__title u-margin-bottom-small">
            <span class="icon-box icon">
              <img src="images/icon/plug.png"/>
              </span>
              Commercial and Electrical Installations
            </h4>
            <p class="paragraph">
              We install and cable associated devices such as switches,
              distribution boards, sockets, and light fittings in a structure.
            </p>
          </div>
          <div class="what-we-offer__content">
            <h4 class="what-we-offer__title u-margin-bottom-small">
            <span class="icon-box icon">
              <img src="images/icon/snowy-mountains.png"/>
              </span>
              Landscaping & External Works
            </h4>
            <p class="paragraph">
              Hard landscaping projects create an interesting outside area to
              your property and will add to its value.
            </p>
          </div>
          <div class="what-we-offer__content">
            <h4 class="what-we-offer__title u-margin-bottom-small">
            <span class="icon-box icon">
              <img src="images/icon/gears.png"/>
              </span>
              Mechanical and Renewables
            </h4>
            <p class="paragraph">
              We design, install, commission, maintain and service a vast range
              of mechanical equipment in Private Housing, Social Housing and
              Commercial properties.
            </p>
          </div>
          <div class="what-we-offer__content">
            <h4 class="what-we-offer__title u-margin-bottom-small">
            <span class="icon-box icon">
              <img src="images/icon/roof.png"/>
              </span>
              Roofing Installation
            </h4>
            <p class="paragraph">
              We provide effective, service-oriented roofing and consistently
              providing our clients with materials of the highest quality.
            </p>
          </div>
        </div>
      </div>
      </div>
  );
}

export default Weoffer