import React from 'react';
function Whyus() {
  return (
    <div>
<div class="why-us">
        <div class="why-us__left">
          <img
            src="images/why-us-img-2x.png"
            alt="Why us"
            class="why-us__img"
            srcset="images/why-us-img-1x.png 1x, images/why-us-img-2x.png 2x"
          />
        </div>

        <div class="why-us__right">
          <h3 class="heading-tertiary u-margin-bottom-small">Why us</h3>
          <h2 class="heading-secondary u-margin-bottom-small">
            Carbon capabilities and journey to net zero
          </h2>
          <p class="paragraph">
            As a progressive business, committed to doing the right thing,
            Galliford Try recognises the urgency of the climate change agenda
            and champions the role we have to play in decarbonising the economy
            for a greener, more sustainable future. As well as driving down our
            own carbon footprint, we work with clients to deliver low and net
            zero projects and work with suppliers and design consultants to help
            everyone in the industry reach their net zero carbon targets.
          </p>
        </div>
      </div>
      </div>
  );
}

export default Whyus