import React from 'react';
function Banner() {
  return (
    <div>
<div class="about">
  <div class="aboutbanner">
    <h1>About</h1>
  </div>
  </div>

  {/* <div class="header__text-box">
    <h1 class="heading-primary u-margin-bottom-small">
      <span class="heading-primary--sub"> If you can Imagine it, </span>
      <span class="heading-primary--main">
        We can <span class="emphasis">build</span> it.
      </span>
    </h1>
  

    <div class="header__form-group">
      <form action="#" class="header__form">
        <input
          class="header__form-input u-margin-bottom-mid"
          type="email"
          name="email"
          id="email"
          placeholder="Enter Your Email"
          autocomplete="off"
          required
        />
        <button class="btn btn--green">Let's Build</button>
      </form>
    </div>
  </div>
</div> */}
</div>
   
  );
}

export default Banner;