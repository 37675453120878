import React from 'react';
import Contactus from '../Home/Contactus';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
function Contact() {
  return (
    <div>
        <Header/>
        <Contactus/>
        <Footer/>
    </div>
   
  );
}

export default Contact;