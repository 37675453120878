import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Banner from './Banner';
import PrivacyComponent from './Privacycomponent';
function Privacy() {
  return (
    <div>
        <Header/>
        <Banner/>
        <PrivacyComponent/>
        <Footer/>
    </div>
   
  );
}

export default Privacy;