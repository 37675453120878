import { EnvironmentOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import React from 'react';
function Footer() {
  return (
    <div>
<div class="footer">
<div class="footer__row-1">
<div class="footer__box  footerlogo" style={{textAlign:"center",}}>
  <img src="images/logo.png"  className="img-fluid" />
  <p>If you can Imagine it <br/>,
We can build it.Our mission as an <br/>organization is to bridge the gap <br/>between Africa and the  <br/>western world in terms of <br/> quality product, service delivery.</p>
  </div>
  <div class="footer__box">
    <h4 class="footer__title">Company</h4>
    <ul class="footer__list">
      <li class="footer__item">
        <a href="/about" class="footer__link">About Us</a>
      </li>
      {/* <li class="footer__item">
        <a href="#" class="footer__link">Estates</a>
      </li> */}
      <li class="#footer__item">
        <a href="/project" class="footer__link">Projects</a>
      </li>
      <li class="footer__item">
        <a href="/gallary" class="footer__link">Gallery</a>
      </li>
      <li class="footer__item">
        <a href="/contact" class="footer__link">Contact</a>
      </li>
    </ul>
  </div>

  <div class="footer__box">
    <h4 class="footer__title">Support</h4>
    <ul class="footer__list">
      <li class="footer__item">
        <a href="#" class="footer__link">Get Help</a>
      </li>
      {/* <li class="footer__item">
        <a href="/faq" class="footer__link">FAQs</a>
      </li> */}
      <li class="#footer__item">
        <a href="/privacy" class="footer__link">Privacy Policy</a>
      </li>
      <li class="footer__item">
        <a href="/terms" class="footer__link">Terms & Conditions</a>
      </li>
    </ul>
  </div>

  <div class="footer__box">
    <h4 class="footer__title">Get In Touch</h4>
    <ul class="footer__list">
    <li class="footer__item">
        <a href="tel:7737765492" class="footer__link"><PhoneOutlined /><span class="iconfooter">Call: 7737765492</span></a>
    </li>
    <li class="footer__item">
        <a href="mailto:prahla72@gmail.com" class="footer__link"><MailOutlined /><span class="iconfooter">Email: prahla72@gmail.com</span></a>
    </li>
    <li class="footer__item">
        <a href="https://www.google.com/maps/place/NIDHI+CONSTRUCTION+COMPANY,+GROUND+FLOOR,PANCHAYAT+SAMITI+KE+PICHHE,+SHOP+NO-03,+LBS+SCHOOL+KE+PASS,+WARD+NO-04,+VILLAGE-GOVINDPURA,+VAYA-PALSANA+TEHSIL-KHANDELA,+Sikar,+Rajasthan,+332402" class="footer__link" target="_blank"><EnvironmentOutlined /><span class="iconfooter">Location: NIDHI CONSTRUCTION COMPANY <br/>GROUND FLOOR,PANCHAYAT SAMITI KE PICHHE,<br/> SHOP NO-03, LBS SCHOOL KE PASS, <br/>WARD NO-04, VILLAGE- GOVINDPURA,<br/> VAYA-PALSANA TEHSIL-KHANDELA,<br/> Sikar, Rajasthan, 332402<br/></span></a>
    </li>
</ul>

  </div>

  {/* <div class="footer__box">
    <label for="language" class="footer__title">Language</label>
    <select name="language" id="language" class="footer__dropdown">
      <option class="footer__dropdown-item">English</option>
      <option class="footer__dropdown-item">French</option>
      <option class="footer__dropdown-item">Spanish</option>
      <option class="footer__dropdown-item">Arabic</option>
    </select>
  </div> */}
</div>

<div class="footer__row-2 u-margin-top-small">
<p class="copyright">Copyright &copy;{new Date().getFullYear()} Nidhi Construction. All Rights Reserved.</p>

  {/* <div class="footer__social-icons-box">
    <a href="#" class="footer__social-link">
      <svg class="social-icon">
        <use xlink="images/sprite.svg#facebook"></use>
      </svg>
      
    </a>
    <a href="#" class="footer__social-link">
      <svg class="social-icon">
        <use xlink ="images/sprite.svg#instagram"></use>
      </svg>
    </a>
    <a href="#" class="footer__social-link">
      <svg class="social-icon">
        <use xlink ="images/sprite.svg#twitter"></use>
      </svg>
    </a>
  </div> */}
</div>


</div>
 </div>
 );
}

export default Footer;