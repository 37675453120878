import React from 'react';
import { Carousel } from 'antd';
// import 'antd/dist/antd.css'; // Import the Ant Design styles
import { Card } from 'antd';

const { Meta } = Card;
const Team = () => {
  const contentStyle = {
    // height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  return (
    <div className='container'>
    
    <div className='headerslider'><h2>Meet Our Team</h2></div>
    <Carousel
        slidesToShow={3}
        autoplay
        autoplaySpeed={3000}
        className="teamslide"
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 585,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
      >
      <div>
      <Card
    hoverable
    style={{ width: 300 }}
    cover={<img alt="example" src="images/team1.jpg" />}
  >
    <Meta title="Europe Street beat" description="www.instagram.com" />
  </Card>
      </div>
      <div>
      <Card
    hoverable
    style={{ width: 300 }}
    cover={<img alt="example" src="images/team1.jpg" />}
  >
    <Meta title="Europe Street beat" description="www.instagram.com" />
  </Card>
      </div>
      <div>
      <Card
    hoverable
    style={{ width: 300 }}
    cover={<img alt="example" src="images/team1.jpg" />}
  >
    <Meta title="Europe Street beat" description="www.instagram.com" />
  </Card>
      </div>
      <div>
      <Card
    hoverable
    style={{ width: 300 }}
    cover={<img alt="example" src="images/team1.jpg" />}
  >
    <Meta title="Europe Street beat" description="www.instagram.com" />
  </Card>
      </div>
      <div>
      <Card
    hoverable
    style={{ width: 300 }}
    cover={<img alt="example" src="images/team1.jpg" />}
  >
    <Meta title="Europe Street beat" description="www.instagram.com" />
  </Card>
      </div>
      {/* <div>
        <h3 style={contentStyle}><img src ="images/team/engineer-construction-site.jpg"/>
        <span>Manager  :  Ally</span>
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}><img src ="images/team/engineer-construction-site.jpg"/>
        <span>Manager  :  Ally</span>
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}><img src ="images/team/engineer-construction-site.jpg"/>
        <span>Manager  :  Ally</span>
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}><img src ="images/team/engineer-construction-site.jpg"/>
        <span>Manager  :  Ally</span>
       </h3>
      </div>
      <div>
        <h3 style={contentStyle}><img src ="images/team/engineer-construction-site.jpg"/>
        <span>Manager  :  Ally</span>
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}><img src ="images/team/modern-businessman-with-folder.jpg"/>
        <span>Manager  :  Ally</span>
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}><img src ="images/team/modern-businessman-with-folder.jpg"/>
        <span>Manager  :  Ally</span>
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}><img src ="images/team/modern-businessman-with-folder.jpg"/>
        <span>Manager  :  Ally</span>
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}><img src ="images/team/modern-businessman-with-folder.jpg"/>
        <span>Manager  :  Ally</span>
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}><img src ="images/team/modern-businessman-with-folder.jpg"/>
        <span>Manager  :  Ally</span>
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}><img src ="images/team/modern-businessman-with-folder.jpg"/>
        <span>Manager  :  Ally</span>
        </h3>
      </div> */}
    </Carousel>
    </div>

  );
};

export default Team;
