import React, { useState } from 'react';
import axios from 'axios';

function Contactus() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/contact', formData);
      alert('Message sent successfully!');
      // Clear form fields
      setFormData({
        name: '',
        email: '',
        message: '',
      });
    } catch (error) {
      console.error('Error sending message:', error);
      alert('An error occurred while sending the message. Please try again later.');
    }
  };
  return (
    <div>
     
<div class="contact-us">
<h3 class="heading-tertiary u-margin-bottom-small">Contact Us</h3>
<div class="contact-us__container">
  <div class="contact-us__left">
    <h2 class="heading-secondary u-margin-bottom-small">Let's Talk</h2>
    <p class="paragraph u-margin-bottom-mid">
      We've found that we deliver the greatest value to our clients when
      we establish ongoing relationships that allow us to materially
      contribute to their long-term success.
    </p>

    <div class="contact-us__inner-content-box">
      <h3 class="contact-us__title u-margin-bottom-small">
        <span class="contact-us__icon-box arr-down">
           <img src="images/icon/drop-down-arrow.png"/>
        </span>
        Visit us personally
      </h3>
      <p class="paragraph u-margin-bottom-small">
      GROUND FLOOR,PANCHAYAT SAMITI KE PICHHE, SHOP NO-03, LBS SCHOOL KE PASS, WARD NO-04, VILLAGE- GOVINDPURA, VAYA-PALSANA TEHSIL-KHANDELA,
      Sikar, Rajasthan, 332402

      </p>

      <div class="contact-us__img-box">
        {/* <img
          src="images/map-2x.png"
          alt="Our Location"
          class="contact-us__img"
          srcset="images/map-1x.png 1x, images/map-2x.png 2x"
        /> */}
         <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28315.185514072433!2d75.41435531846888!3d27.4879892547631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396ce985e2a4f38f%3A0xb7b4a2435e84c0c6!2sGovindpura%2C%20Rajasthan%20332402!5e0!3m2!1sen!2sin!4v1706350592476!5m2!1sen!2sin"
      width="100%"
      height="280"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      title="Google Maps Location"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
      </div>
    </div>
  </div>

  <div class="contact-us__right">
    <form action="#" class="contact-us__form form">
      <div class="form__col form__col--1 u-margin-bottom-small">
        <div class="form__group">
          <label for="name" class="form__label">Name</label>
          <input
            class="form__input"
            type="name"
            name="name"
            id="name"
            placeholder="Michael Mensah"
            autocomplete="off"
            required
          />
        </div>

        <div class="form__group">
          <label for="contact-email" class="form__label">Email</label>
          <input
            class="form__input"
            type="email"
            name="contact-email"
            id="contact-email"
            placeholder="Micheal@example.com"
            autocomplete="off"
            required
          />
        </div>
      </div>

      <div class="form__col form__col--2 u-margin-bottom-mid">
        <div class="form__group">
          <label for="message" class="form__label">Message</label>
          <textarea
            name="message"
            id="message"
            class="form__textarea"
            required
            placeholder="Say Something..."
          ></textarea>
        </div>
      </div>

      <button class="btn btn--green">Send us a message</button>
    </form>
  </div>
</div>
</div>
</div>
  );
}

export default Contactus;