import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';


function Header() {
  const location = useLocation();
  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
      isOpen === true ? setIsopen(false) : setIsopen(true);
  }
  return (
    <div>
      <div className="header">
        <div className="header__menu-bar">
          <div className="header__logo-box">
            <img src="images\logo.png" className="img-fluid" alt="Logo" />
          </div>

          <nav className="header__nav">
            <ul className="header__nav-list">
              <li className="header__nav-item">
                <NavLink
                  to="/"
                  className="header__nav-link"
                  activeClassName="header__nav-link--active"
                >
                  Home
                </NavLink>
              </li>
              <li className="header__nav-item">
                <NavLink
                  to="/about"
                  className="header__nav-link"
                  activeClassName="header__nav-link--active"
                >
                  About Us
                </NavLink>
              </li>
              <li className="header__nav-item">
                <NavLink
                  to="/project"
                  className="header__nav-link"
                  activeClassName="header__nav-link--active"
                >
                  Projects
                </NavLink>
              </li>
              <li className="header__nav-item">
                <NavLink
                  to="/gallery"
                  className="header__nav-link"
                  activeClassName="header__nav-link--active"
                >
                  Gallery
                </NavLink>
              </li>
              <li className="header__nav-item">
                <NavLink
                  to="/contact"
                  className="header__nav-link"
                  activeClassName="header__nav-link--active"
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </nav>

          <div className="header__btn-box">
            <a href="#" className="btn btn--white">
              Login
            </a>
            <a href="#" className="btn btn--green">
              Sign Up
            </a>
          </div>
        
        </div>
      </div>
      <>
            <div className="">
                
                    <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-md">
                        <div className="responsivheader">
                            <a className="navbar-brand text-primary mr-0"><img src="images/logo.png"/></a>
                            {/* <div className="form-inline ml-auto"> */}
                                <div className="btn btn-primary" onClick={ToggleSidebar} >
                                    <i className="fa fa-bars"></i>
                                </div>
                            {/* </div> */}
                        </div>
                    </nav>
                    <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
                        <div className="sd-header">
                            <h4 className="mb-0"><img src="images/logo.png"/></h4>
                            <div className="btn btn-primary" onClick={ToggleSidebar}><i className="fa fa-times"></i></div>
                        </div>
                        <div className="sd-body">
                        
                            <ul>
                                <li><Link to="/" className="sd-link">Home</Link></li>
                                <li><Link to="/about" className="sd-link">About</Link></li>
                                <li><Link to="/project" className="sd-link">Project</Link></li>
                                <li><Link to="/gallery" className="sd-link">Gallery</Link></li>
                                <li><Link to="/contact" className="sd-link"> Contact Us</Link></li>
                             </ul>
                             <div className="header__btn-box">
            <a href="#" className="btn btn--white">
              Login
            </a>
            <a href="#" className="btn btn--green">
              Sign Up
            </a>
          </div>
                        </div>
                    </div>
                    <div className={`sidebar-overlay ${isOpen == true ? 'active' : ''}`} onClick={ToggleSidebar}></div>
           </div>
           
        </>
    </div>
  );
}

export default Header;
