import React from 'react';
function TermsComponent() {
  return (
    <div>
<div class="terms">
  <div class="">
   <img class="img-fluid"src="images/coming-soon.png"/>
  </div>
  </div>
</div>
   
  );
}

export default TermsComponent;