import React from 'react';
function Aboutdetail() {
  return (
    <div> 
<div className='container-fluid aboutdetail'>
    <div className='container'>
        <div className='row'>
        <div className='col-6'>
            <img src="images/solveprob.jpg"></img>
        </div>
        <div className='col-6'>
            <h3 className='heading-tertiary u-margin-bottom-small'>
            About us
            </h3>
            <h2>
            We Build for Your Comfort
            </h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
            <button className='btn btn--green'>More About Us</button>
        </div>
        </div>
    </div>
</div>
    </div>
    );
}

export default Aboutdetail;