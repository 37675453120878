import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Photos from './Photos';
import Banner from './Banner';
function Gallery() {
  return (
    <div>
        <Header/>
        <Banner/>
        <Photos/>
        <Footer/>
     </div>
  );
}

export default Gallery