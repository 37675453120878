import React, { useEffect } from 'react';
function Photos() {

  return (
    <div>

<main class="main p-b90 photosmargin">
<div class="heading__fix u-center-text"><h3 class="heading-tertiary u-margin-bottom-small">Our Gallery</h3></div>
     <div class="container containeres">

       <div class="card">
         <div class="card-image">
         <a href="images/1.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/1.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
           <a href="images/2.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/2.jpeg" alt="Image Gallery"/>
           </a>
         </div>
     </div>
       <div class="card">
         <div class="card-image">
         <a href="images/3.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/3.jpeg" alt="Image Gallery"/>
           </a>
         </div>
     </div>
       <div class="card">
         <div class="card-image">
         <a href="images/4.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/4.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
         <a href="images/5.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/5.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
         <a href="images/6.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/6.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
     </div>
   </main>
     </div>
  );
}

export default Photos