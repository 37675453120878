import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Mission from '../Home/Mission';
import Team from './Team';
import Whyus from '../Home/Whyus';
import Banner from './Banner';
import Aboutdetail from './Aboutdetail';
function About() {
  return (
    <div>
        <Header/>
        <Banner/>
        <Aboutdetail/>
        <Mission/>
        <Whyus/>
        <Team />
        <Footer/>
     </div>
  );
}

export default About