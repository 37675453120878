import React from 'react';
function PrivacyComponent() {
  return (
    <div>
<div class="privacy">
  <div class="">
   <img class="img-fluid"src="images/coming-soon.png"/>
  </div>
  </div>
</div>
   

  );
}

export default PrivacyComponent;