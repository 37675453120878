import logo from './logo.svg';
import './App.css';
import './fancybox.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import HomeComponent from './Component/Home/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Mission from './Component/Home/Mission';
import Weoffer from './Component/Home/Weoffer';
import Whowe from './Component/Home/Whowe';
import Whyus from './Component/Home/Whyus';
import About from './Component/About/About';
import Contact from './Component/Contact/Contact';
import Project from './Component/Project/Project';
import 'bootstrap/dist/css/bootstrap.min.css';
import Gallery from './Component/Gallary/Gallary';
import Terms from './Component/Terms/Terms';
import Privacy from './Component/Privacy/Privacy';


function App() {
  return (
    <div>


 <BrowserRouter>
<Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/weoffer" element={<Weoffer />} />
        <Route path="/whowe" element={<Whowe />} />
        <Route path="/whyus" element={<Whyus />} />
        <Route path="/about" element={<About />} />
        <Route path="/project" element={<Project />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        
      </Routes>

      
   </BrowserRouter> 

    </div>

  );
}

export default App;
