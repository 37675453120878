import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Whyus from './Whyus';
import Mission from './Mission';
import Weoffer from './Weoffer';
import Whowe from './Whowe';
import Contactus from './Contactus';
import Banner from './Banner';
function HomeComponent() {
  return (
    <div>
      <>
      <Header/>
      <Banner/>
      <Whowe/>
      <Weoffer/>
<Mission/>
<Whyus/>
<Contactus/>
<Footer/>

    </>
    </div>
  );
}

export default HomeComponent;