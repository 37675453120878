import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Projectservices from './Projectservices';
function Servicebar() {
  return (
    <div>
<div id="services" class="services">
<div class="container">

  <div class="section-title">
    <h2 class="text-center py-5">Services</h2>
   
  </div>

  <div class="row">
    <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
      <div class="des-box">
        <div class="icon"><i class="fas fa-cubes"></i></div>
        <h4 class="title"><a href="">Building Staff</a></h4>
        <p class="description">
        Phaseus site amet tristi que ligua donec leo sus cipit.</p>
      </div>
    </div>

    <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
      <div class="des-box">
        <div class="icon"><i class="far fa-edit"></i></div>
        <h4 class="title"><a href="">Renovation</a></h4>
        <p class="description">Phaseus site amet tristi que ligua donec leo sus cipit.</p>
      </div>
    </div>

    <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
      <div class="des-box">
        <div class="icon"><i class="fas fa-th"></i></div>
        <h4 class="title"><a href="">Roof Repairing</a></h4>
        <p class="description">Phaseus site amet tristi que ligua donec leo sus cipit.</p>
      </div>
    </div>

    <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
      <div class="des-box">
        <div class="icon"><i class="fas fa-archway"></i></div>
        <h4 class="title"><a href="">Architectural</a></h4>
        <p class="description">Phaseus site amet tristi que ligua donec leo sus cipit.</p>
      </div>
    </div>
    <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
      <div class="des-box">
        <div class="icon"><i class="fas fa-archway"></i></div>
        <h4 class="title"><a href="">Interior</a></h4>
        <p class="description">Phaseus site amet tristi que ligua donec leo sus cipit.</p>
      </div>
    </div>
    <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
      <div class="des-box">
        <div class="icon"><i class="fas fa-archway"></i></div>
        <h4 class="title"><a href="">Smart Houses</a></h4>
        <p class="description">Phaseus site amet tristi que ligua donec leo sus cipit.</p>
      </div>
    </div>

  </div>

</div>
</div>
</div>
   
  );
}

export default Servicebar;