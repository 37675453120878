import React from 'react';
function Work() {
  return (
   <div>
<main class="main p-b90 photosmargin">
<div class="heading__fix u-center-text"><h3 class="heading-tertiary u-margin-bottom-small">Our Projects</h3></div>
     <div class="container containeres">

       <div class="card">
         <div class="card-image">
         <a href="images/1.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/1.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
           <a href="images/2.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/2.jpeg" alt="Image Gallery"/>
           </a>
         </div>
     </div>
       <div class="card">
         <div class="card-image">
         <a href="images/3.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/3.jpeg" alt="Image Gallery"/>
           </a>
         </div>
     </div>
       <div class="card">
         <div class="card-image">
         <a href="images/4.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/4.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
         <a href="images/5.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/5.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
         <a href="images/6.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/6.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>

       <div class="card">
         <div class="card-image">
         <a href="images/7.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/7.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
         <a href="images/8.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/8.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
         <a href="images/9.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/9.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
         <a href="images/10.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/10.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
         <a href="images/11.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/11.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
         <a href="images/12.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/12.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
         <a href="images/13.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/13.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
         <a href="images/14.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/14.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>
       <div class="card">
         <div class="card-image">
         <a href="images/6.jpeg" data-fancybox="gallery" data-caption="Construction Foundation">
             <img src="images/6.jpeg" alt="Image Gallery"/>
           </a>
         </div>
       </div>

     </div>
   </main>
     </div>
   
  );
}

export default Work;