import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Projectservices from './Projectservices';
import Servicebar from './Servicebar';
import Banner from './Banner';
import Work from './Work';
function Project() {
  return (
    <div>
        <Header/>
        <Banner/>
        <Servicebar/>
        <Projectservices/>
        <Work/>
        <Footer/>
    </div>
   
  );
}

export default Project;