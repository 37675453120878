import React from 'react';
import Contactus from '../Home/Contactus';
function Projectservices() {
  return (
    <div>


<div class="project " id="projects">
<div class="content-box">
            <div class="container">
        <div class="row text-center">
            <div class="col-md-3"> 
                <div class="project-items">
                    <i class="fas fa-building"></i>
                    <h2><span class="counter text-center ">500</span></h2>
                    <p>Finished Projects</p>
                </div>
                
            </div>
               
               <div class="col-md-3"> 
                <div class="project-items">
                    <i class="fas fa-city"></i>
                    <h2><span class="counter text-center">250+</span> </h2>
                    <p>Years of Experience</p>
                </div>
                
            </div>
               
               <div class="col-md-3"> 
                <div class="project-items">
                    <i class="fas fa-truck-loading"></i>
                    <h2>
                      <span class="counter text-center">100+</span>
                       </h2>
                       <p>Awards winning</p>
                   
                </div>
                
            </div>
                                     
               <div class="col-md-3"> 
                <div class="project-items">
                    <i class="fas fa-torii-gate"></i>
                    <h2><span class="counter text-center">100%</span> </h2>
                    <p>Happy Clients</p>
                   
                </div>
                
            </div>
        </div>
        
    </div>
    
</div>          
            
</div>
</div>
   
  );
}

export default Projectservices;